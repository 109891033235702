<template>
  <v-card class="elevation-2">
    <v-card-title>
      <v-row>
        <v-col cols="12" sm="6" class="d-flex justify-center justify-sm-start">
          <h3>Lessons</h3>
        </v-col>
        <v-col cols="12" class="d-flex d-sm-none">
          <FilterSelect
            filterLabel="Quarter"
            :filterOptions="quarterOptions"
            @change="filterLessonsByQuarter = $event"
          />
        </v-col>
        <v-row class="flex-md-row-reverse">
          <v-col
            class="d-flex justify-md-end"
            cols="12"
            :md="quarterOptions.length > 2 ? '12' : '6'"
          >
            <SearchQuery @search="searchInputQuery = $event" />
          </v-col>
          <v-col
            class="d-none d-sm-flex align-end pa-2"
            cols="12"
            :md="quarterOptions.length > 2 ? '12' : '6'"
          >
            <v-btn-toggle
              v-model="filterLessonsByQuarter"
              :color="`${subject.color} darken-3`"
              mandatory
            >
              <v-btn
                v-for="quarter in quarterOptions"
                :key="quarter"
                :value="quarter"
                large
                text
              >
                Quarter {{ quarter }}
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!filteredLessons.length > 0" class="py-4">
      <v-banner two-line>
        <v-icon block slot="icon" color="info" size="36">
          mdi-information-outline
        </v-icon>
        No Lessons Found.
      </v-banner>
    </v-card-text>
    <v-card-text v-else class="py-6">
      <v-row>
        <v-col
          v-for="(lesson, index) in filteredLessons"
          :key="index"
          xl="4"
          lg="4"
          md="6"
          sm="6"
          cols="12"
        >
          <v-card tile elevation="2">
            <v-sheet
              tile
              :class="`secondary-${subject.color}-bg py-2 folder-style-no-skew`"
            ></v-sheet>
            <v-sheet
              tile
              :class="`secondary-${subject.color}-bg py-2 ml-5 folder-style-skew`"
            ></v-sheet>
            <v-card-text class="pa-2">
              <v-tooltip :open-on-hover="false" left>
                <template v-slot:activator="{ on, attrs }">
                  <h4 class="text-truncate" v-bind="attrs" v-on="on">
                    {{ lesson.title }}
                  </h4>
                </template>
                <span> {{ lesson.title }}</span>
              </v-tooltip>
              <div class="mt-10 d-flex justify-end">
                <v-btn
                  small
                  rounded
                  right
                  :class="`main-${subject.color}-bg white--text`"
                  :to="{
                    name: 'LessonInformation',
                    params: { id: lesson.id },
                  }"
                  >READ MORE
                  <v-icon small> mdi-chevron-double-right </v-icon>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import SearchQuery from "@/components/SearchQuery";
import FilterSelect from "@/components/FilterSelect";
export default {
  name: "LessonAssigned",
  components: {
    SearchQuery,
    FilterSelect,
  },
  data() {
    return {
      filterLessonsByQuarter: "",
      searchInputQuery: "",
    };
  },
  computed: {
    ...mapState({
      lessons: (state) => state.lessonsModule.lessons,
      lessonsLoading: (state) => state.lessonsModule.lessonsLoading,
      subject: (state) => state.subjectsModule.subject,
      quarterOptions: (state) => state.subjectsModule.quarterOptions,
    }),
    filteredLessons() {
      let lessonsByQuarter = [],
        quarter = this.filterLessonsByQuarter,
        search = this.searchInputQuery;

      if (this.lessons) {
        if (this.lessonsAreYearLong) {
          lessonsByQuarter = this.lessons;
        } else {
          lessonsByQuarter = this.lessons.filter(
            (lesson) => lesson.quarter == quarter
          );
        }

        if (search) {
          lessonsByQuarter = lessonsByQuarter.filter((lesson) =>
            lesson.title.toLowerCase().includes(search.toLowerCase())
          );
        }
      }

      return lessonsByQuarter;
    },
  },
  methods: {},
  async mounted() {
    const subjectId = this.$store.state.subjectsModule.subject.id;
    await this.$store.dispatch("lessonsModule/getLessons", subjectId);
  },
};
</script>
<style scoped>
.folder-style-no-skew {
  width: 20%;
  position: absolute;
  margin-top: -16px;
}
.folder-style-skew {
  width: 35%;
  position: absolute;
  margin-top: -16px;
  transform: skewX(50deg);
  -webkit-transform: skewX(50deg);
  -moz-transform: skewX(50deg);
  -ms-transform: skewX(50deg);
}
</style>
